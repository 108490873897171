var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.billing_account_)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Basic","params":_vm.billing_account_,"class_name":"BillingAccount","with_properties":['company_registration_number', 'gst_registration_number', 'credit_terms_days'],"with_relationships":[
              {
                from_class_name: 'BillingAccount',
                relationship_name: 'CurrentlyManagedBy',
              },
              {
                from_class_name: 'BillingAccount',
                relationship_name: 'DeliveryOrderInvoicingPreference',
              },
            ]},on:{"update:params":function($event){_vm.billing_account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Has Accounts","params":_vm.billing_account_,"relationship":_vm.$d.getRelationship('BillingAccount', 'HasAccounts')},on:{"update:params":function($event){_vm.billing_account_=$event},"submitted":function($event){return _vm.$emit('submitted')}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Has Main Account","params":_vm.billing_account_,"relationship":_vm.$d.getRelationship('BillingAccount', 'HasMainAccount')},on:{"update:params":function($event){_vm.billing_account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Main Invoice Contact Persons","params":_vm.billing_account_,"relationship":_vm.$d.getRelationship('BillingAccount', 'HasMainInvoiceContactPersons')},on:{"update:params":function($event){_vm.billing_account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Tags","params":_vm.billing_account_,"relationship":_vm.$d.getRelationship('BillingAccount', 'Tags'),"relationship_index_params":{
              //with_virtual_properties: [],
              with_relationships: [
                //TODO: need to better automate!!!
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'OldCompanySector',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'CompanySector',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'OldCompanyType',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'CompanyType',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'NewStatus',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'OldStatus',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'Status',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'OldSubstatus',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'Substatus',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'OrderType',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'OrderFrequency',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'PaymentStyle',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'TstopReason',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'CaStatus',
                },
                {
                  from_class_name: 'BillingAccountTags',
                  relationship_name: 'PoRequirement',
                },
              ],
            }},on:{"update:params":function($event){_vm.billing_account_=$event}}})],1)],1)],1),_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Billing Address","params":_vm.billing_account_,"relationship":_vm.$d.getRelationship('BillingAccount', 'WithBillingAddress'),"reverse_action_bar":false,"relationship_index_params":{
              //with_virtual_properties: [],
              with_relationships: [
                {
                  from_class_name: 'Address',
                  relationship_name: 'UnderOldDepartment',
                },
                {
                  from_class_name: 'Address',
                  relationship_name: 'UnderDepartment',
                },
                {
                  from_class_name: 'Address',
                  relationship_name: 'UnderState',
                },
              ]
            }},on:{"update:params":function($event){_vm.billing_account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alert alert-primary",attrs:{"role":"alert"}},[_vm._v("\n            Assignment of Account Managers to a Billing Account will affect Commissions\n          ")]),_c('DescriptorCard',{attrs:{"fixed_params":_vm.$d.relationFixedParams('BillingAccountAccountManager', 'ForBillingAccount', [_vm.billing_account_]),"_index_params":_vm.$d.relationIndexParams('BillingAccountAccountManager', 'ForBillingAccount', _vm.billing_account_.id),"stateIdentifier":'_billing_account_' + _vm.billing_account.id,"class_name":"BillingAccountAccountManager","title":"Account Managers","small":true,"default_per_page":5}})],1)],1)],1)],1)],1):_c('div',[_c('div',{staticClass:"alert alert-warning"},[_vm._v("Billing Account Required")])])
}
var staticRenderFns = []

export { render, staticRenderFns }