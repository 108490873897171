<template>
  <div v-if="billing_account_">
    <b-row>
      <b-col xl="6" lg="6" md="12" sm="12">
        <b-row>
          <b-col cols="12">
            <EditableCard
              title="Basic"
              :params.sync="billing_account_"
              class_name="BillingAccount"
              :with_properties="['company_registration_number', 'gst_registration_number', 'credit_terms_days']"
              :with_relationships="[
                {
                  from_class_name: 'BillingAccount',
                  relationship_name: 'CurrentlyManagedBy',
                },
                {
                  from_class_name: 'BillingAccount',
                  relationship_name: 'DeliveryOrderInvoicingPreference',
                },
              ]"
            />
          </b-col>

          <b-col cols="12">
            <EditableCard
              title="Has Accounts"
              :params.sync="billing_account_"
              :relationship="$d.getRelationship('BillingAccount', 'HasAccounts')"
              @submitted="$emit('submitted')"
            />
          </b-col>

          <b-col cols="12">
            <EditableCard title="Has Main Account" :params.sync="billing_account_" :relationship="$d.getRelationship('BillingAccount', 'HasMainAccount')" />
          </b-col>

          <b-col cols="12">
            <EditableCard
              title="Main Invoice Contact Persons"
              :params.sync="billing_account_"
              :relationship="$d.getRelationship('BillingAccount', 'HasMainInvoiceContactPersons')"
            />
          </b-col>

          <b-col cols="12">
            <EditableCard
              title="Tags"
              :params.sync="billing_account_"
              :relationship="$d.getRelationship('BillingAccount', 'Tags')"
              :relationship_index_params="{
                //with_virtual_properties: [],
                with_relationships: [
                  //TODO: need to better automate!!!
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'OldCompanySector',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'CompanySector',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'OldCompanyType',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'CompanyType',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'NewStatus',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'OldStatus',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'Status',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'OldSubstatus',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'Substatus',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'OrderType',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'OrderFrequency',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'PaymentStyle',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'TstopReason',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'CaStatus',
                  },
                  {
                    from_class_name: 'BillingAccountTags',
                    relationship_name: 'PoRequirement',
                  },
                ],
              }"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col xl="6" lg="6" md="12" sm="12">
        <b-row>
          <b-col cols="12">
            <EditableCard
              title="Billing Address"
              :params.sync="billing_account_"
              :relationship="$d.getRelationship('BillingAccount', 'WithBillingAddress')"
              :reverse_action_bar="false"
              :relationship_index_params="{
                //with_virtual_properties: [],
                with_relationships: [
                  {
                    from_class_name: 'Address',
                    relationship_name: 'UnderOldDepartment',
                  },
                  {
                    from_class_name: 'Address',
                    relationship_name: 'UnderDepartment',
                  },
                  {
                    from_class_name: 'Address',
                    relationship_name: 'UnderState',
                  },
                ]
              }"
            />
          </b-col>

          <b-col cols="12">
            <div class="alert alert-primary" role="alert">
              Assignment of Account Managers to a Billing Account will affect Commissions
            </div>
            <DescriptorCard
              :fixed_params="$d.relationFixedParams('BillingAccountAccountManager', 'ForBillingAccount', [billing_account_])"
              :_index_params="$d.relationIndexParams('BillingAccountAccountManager', 'ForBillingAccount', billing_account_.id)"
              :stateIdentifier="'_billing_account_' + billing_account.id"
              class_name="BillingAccountAccountManager"
              title="Account Managers"
              :small="true"
              :default_per_page="5"
            ></DescriptorCard>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div class="alert alert-warning">Billing Account Required</div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  components: {
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
    TabView: () => import('@/descriptor/coreui/components/modal/TabView'),
  },
  props: {
    billing_account: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    billing_account_: {
      get: function() {
        return this.billing_account;
      },
      set: function(newValue) {
        this.$emit('update:billing_account', newValue);
      },
    },
  },
  created() {},
  methods: {},
};
</script>
